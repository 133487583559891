import React from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GalleryHeader from "@components/klips/gallery-header"
import { HTMLWrapper } from "@components/common/html-wrapper"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import Image from "@components/image"
import Grid from "@components/elements/grid"
import {
  ExampleGridItem,
} from "@components/resources/example-grid"
import Markdown from "react-markdown"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import Flex from "@components/elements/flex"
import styled, { useTheme } from "styled-components"
import supersub from "remark-supersub"
import { KLIPS_COLOURS } from "../../styles/colors"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const KlipButton = styled.a`
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  background: ${props => (props.klip ? "#18aed8" : "#b8bfd6")};
  font-weight: 700;
  border-radius: 0.5rem;
  text-align: center;
  transition: filter 0.3s ease-in-out !important;

  &:hover {
    filter: brightness(0.9);
  }
`

const KlipsSolutions = ({ data }) => {
  const solution = data.solution
  const header = {
    title: solution.heading,
    alt: `Track your ${solution.name?.toLowerCase()} KPIs in minutes`,
    description: solution.tagline,
    image: solution.image,
  }
  const { color, shadow, spacing } = useTheme()

  return (
    <Layout
      seo={{
        title: "Klips Data Dashboard Integrations",
        description: `Dashboard integrations and pre-built connectors for over 100 of your favourite apps. Start a free trial, find an integration, and add it to your dashboard.`,
      }}
      fullWidth={true}
    >
      <article>
        <GalleryHeader
          name={header.title}
          alt={header.alt}
          cta={header.alt}
          subtitle={header.description}
          newsletterCta
          shadow={false}
          kpi
          links={[
            {
              text: "Solutions",
              url: "/solutions",
            },
            {
              text: solution.name,
            },
          ]}
          image={header.image}
        />
        <Div margin="6rem auto 0" textContainer>
          <HTMLWrapper
            styles={KLIPS_COLOURS}
            html={solution.body_1}
            images={solution.body_1_images}
          />
          <Heading
            as="h2"
            center
            color={color.blue600}
            margin="12rem auto 1rem"
            marginMd="8rem auto 1rem"
            marginSm="6rem auto 1rem"
          >
            {solution.name} dashboard examples
          </Heading>
          <Paragraph
            maxWidth="600px"
            margin="0 auto 3rem"
            lineHeight={1.6}
            fontWeight={600}
            center
            color={color.blue600}
          >
            Curious to see what {solution.name?.toLowerCase()} data you can track
            in Klipfolio? View these live dashboard examples to get a better
            look.
          </Paragraph>
        </Div>
        <Grid {...spacing.large} gap="2rem" container columns="1fr 1fr" columnsMd="1fr">
          {solution.live_dashboards.map((dashboard, index) => (
            <ExampleGridItem key={index}>
              <Div background={color.blueLight} height={350} padding="2rem" borderRadius="0.5rem 0.5rem 0 0">
                <Image
                  file={dashboard.image}
                  radius={0.5}
                  borderStyle={{ boxShadow: shadow.default }}
                />
              </Div>
              <Flex height="100%" gap="1rem" padding="1.5rem">
                <Heading letterSpacing="0" as="h3" fontSize="1.2rem">
                  <Anchor color={color.blue600} arrow to={dashboard.link}>
                    {dashboard.name}
                  </Anchor>
                </Heading>
                <Div>
                  <Paragraph fontSize="1rem">
                    <Markdown remarkPlugins={[supersub]} className="prose">
                      {dashboard.description.description}
                    </Markdown>
                  </Paragraph>
                </Div>
                <Flex margin="auto 0 0" gap="0.5rem">
                  <KlipButton href={dashboard.link} klip>
                    Get This Dashboard
                  </KlipButton>
                </Flex>
              </Flex>
            </ExampleGridItem>
          ))}
        </Grid>
        <Div {...spacing.large} textContainer>
          <HTMLWrapper
            styles={KLIPS_COLOURS}
            html={solution.body_2}
            images={solution.body_2_images}
          />
        </Div>
        <Div {...spacing.large} textContainer>
          <HTMLWrapper
            styles={KLIPS_COLOURS}
            html={solution.body_3}
            images={solution.body_3_images}
          />
        </Div>
        <KlipsEOPCta />
      </article>
    </Layout>
  )
}

KlipsSolutions.propTypes = {
  data: PropTypes.object.isRequired,
}

export default KlipsSolutions

export const pageQuery = graphql`
  query KlipsSolutionsTemplate($id: String!) {
    solution: klipsSolutions(id: { eq: $id }) {
      id
      seo {
        ...seoData
      }
      image {
        ...imageData
      }
      name
      slug
      tagline
      heading
      body_1
      body_1_images {
        ...imageData
      }
      live_dashboards {
        image {
          ...imageData
        }
        description {
          description
        }
        category
        name
        link
      }
      body_2
      body_2_images {
        ...imageData
      }
      klips_services {
        id
        logo {
          ...imageData
        }
        name
        slug
      }
      body_3
      body_3_images {
        ...imageData
      }
    }
    gallery: allGalleryKlips(filter: { status: { eq: "published" } }) {
      edges {
        node {
          image {
            ...imageData
          }
          description
          description_gallery
          services {
            id
            name
            logo {
              ...imageData
            }
            slug
            type
          }
          name
          link
          slug
        }
      }
    }
  }
`
